<template>
<div >
    <div class="card mt-5" style="width: 60%">
    <div class="card-body login-card-body mt-3">
      <h3 class="login-box-msg" style="text-align:center">ข้อมูลส่วนตัว</h3>
      <form>
        <div class="card-body mt-3">
          <div class="form-group">
            <label for="username">ชื่อผู้ใช้งาน</label>
            <input
              v-model="user.username"
              v-on:keyup.enter="signIn()"
              type="text"
              min="1"
              class="form-control form-control-sm"
              id="username"
              placeholder="กรุณากรอกชื่อผู้ใช้งาน"
            />
          </div>
          <div class="form-group mt-3">
            <label for="password">รหัสผ่าน</label>
            <input
              v-model="user.password"
              v-on:keyup.enter="signIn()"
              type="password"
              class="form-control form-control-sm"
              id="password"
              placeholder="กรุณากรอกรหัสผ่าน"
            />
            <small id="emailHelp" class="form-text text-muted">รหัสผ่านต้องประกอบด้วย<br>
  ตัวพิมพ์ใหญ่ A ถึง Z (อย่างน้อย 1 ตัวอักษร)<br>
  ตัวพิมพ์เล็ก a ถึง z (อย่างน้อย 1 ตัวอักษร)<br>
ตัวเลข 0 ถึง 9 (อย่างน้อย 1 ตัวอักษร)<br>
สัญลักษณ์พิเศษ (อย่างน้อย 1 ตัวอักษร)</small>
          </div>
          <div class="social-auth-links text-center mb-3 mt-3">
            <button @click="signIn()" type="button" class="btn btn-success btn-sm">บันทึก</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import UserService from '../services/UserService.js'
export default {
  name: "Nav",  
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id:0,
      menu:[],
      user:{},
      hash:''
    }
  },
  mounted() {
    UserService.getUser(this.currentUser.id).then((res)=>{
      this.user = res.data
      this.hash = res.data.password
    })
  },
  methods: {
     signIn() {
      var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

      if (this.user.username == "" || this.user.username == null) {
        alert("กรุณากรอกชื่อผู้ใช้งาน");
      } else if (this.user.password == "" || this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else if (!regex.test(this.user.password ) && (String(this.user.password )).length < 8){
  alert("กรุณากรอกรหัสผ่านให้ตรงตามข้อกำหนด");
}else {
        var user = {
          username: this.user.username,
          password: this.user.password,
          hash:this.hash
        };
        UserService.updateUser(this.currentUser.id,user).then(()=>{
          alert('บันทึกสำเร็จ')
        })
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04AA6D;
  color: white;
}
.card {
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
}
body{
background-color: gray;
}
</style>
