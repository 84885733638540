import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:8080/api",

  baseURL : "https://api-eva-transport.visdchiangrai.com/api",

  
  headers: {
    "Content-type": "application/json",
  }
});