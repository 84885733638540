import Vue from "vue";
import Router from "vue-router";
import Login from './components/Login.vue';
import Users from './components/Users.vue';
import Menu from './components/Menu.vue';
import qrcode from './components/qrcode.vue';
import addqrcode from './components/addqrcode.vue';
import evaluation from './components/evaluation.vue';
import header from './components/header.vue';
import Office from './components/Office.vue';
import evaluationall from './components/evaluationall.vue';
import Menumanage from './components/Menumanage.vue';
import report from './components/report.vue';
import Profile from './components/Profile.vue';

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/login",
      name: "login",
      component: Login
    },
{
  path: "/user",
  alias: "/user",
  name: "user",
  component: Users       
},
{
  path: "/menu",
  alias: "/menu",
  name: "menu",
  component: Menu       
},
{
  path: "/qrcode",
  alias: "/qrcode",
  name: "qrcode",
  component: qrcode       
},
{
  path: "/addqrcode/:type/:id",
  alias: "/addqrcode",
  name: "addqrcode",
  component: addqrcode       
},
{
  path: "/evaluation/:type/:id",
  alias: "/evaluation",
  name: "evaluation",
  component: evaluation       
},
{
  path: "/header",
  alias: "/header",
  name: "header",
  component: header       
},
{
  path: "/office",
  alias: "/office",
  name: "office",
  component: Office       
},
{
  path: "/evaluationall",
  alias: "/evaluationall",
  name: "evaluationall",
  component: evaluationall       
},
{
  path: "/Menumanage",
  alias: "/Menumanage",
  name: "Menumanage",
  component: Menumanage       
},
{
  path: "/report",
  alias: "/report",
  name: "report",
  component: report       
},
{
  path: "/profile",
  alias: "/profile",
  name: "profile",
  component: Profile       
},

  ]
});

Vue.use(Router);

router.beforeEach((to, from, next) => {
  var page = to.path.split("/")
  var qrcode = ''
  if (page[1] == 'evaluation') {
    qrcode = to.path
  }
  console.log(page[1]);
  console.log(qrcode);
  const publicPages = ['/','/login',qrcode];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('userjrc');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) { 
    next('qrcode');
  } else {
    next();
  }

});

export default router;
