<template>
<div class="container-fluid">
  <div v-if="statustable">
  <button type="button" class="btn btn-primary mt-3 btn-sm" @click="getstatus(1)">บุคคล</button>&nbsp;
<button type="button" class="btn btn-secondary mt-3 btn-sm" @click="getstatus(2)">หน่วยงาน</button>
</div>
<div v-else>
  <button type="button" class="btn btn-secondary mt-3 btn-sm" @click="getstatus(1)">บุคคล</button>&nbsp;
<button type="button" class="btn btn-primary mt-3 btn-sm" @click="getstatus(2)">หน่วยงาน</button>
</div>
<div class="container-fluid mt-3">
  <div class="row"> 
      <div class="col-6">
        <label for="exampleInputEmail1" class="form-label">วันเริ่มต้น</label>
        <!-- <input
          v-model="startdate"
          type="date"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        /> -->
        <input id="start_date" class="form-control" />
      </div>
      <div class="col-6">
        <label for="exampleInputEmail1" class="form-label">วันสิ้นสุด</label>
        <!-- <input
          v-model="enddate"
          type="date"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        /> -->
        <input id="end_date" class="form-control" />
      </div>
      <div class="col-12" align="center">
        <button
          @click="search()"
          type="submit"
          class="btn btn-primary btn-sm"
          style="margin-top: 10px;text-align:right"
        >
          ค้นหา
        </button>
      </div>
      </div>
  <div class="col mb-3" style="text-align: right">
    <a @click="exportToPDF()">
        <button class="btn btn-danger">
          <i class="fa fa-file-pdf"></i> 
        </button></a
      >&nbsp;
      <a @click="exportTableToExcel('tblData')">
        <button class="btn btn-success">
          <i class="fa fa-file-excel"></i> 
        </button></a
      >
     
  </div>
<table class="table table-borderless" id="tblData" ref="document" v-if="statustable">
  <thead>
    <tr>
  <td style="text-align:center;" colspan="10"><span style="font-weight:bold;font-size:20px">รายงานผลการประเมินความพึงพอใจการให้บริการรายบุคคล</span><br/><span style="font-weight:normal;font-size:18px">ระหว่างวันที่ {{startdatename}} ถึงวันที่ {{enddatename}}</span></td>
</tr>
  </thead>
  <thead>
    <tr>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">ลำดับ</th>
      <!-- <th scope="col">รูปภาพ</th> -->
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">ชื่อ-สกุล</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">จำนวนผู้ประเมิน</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">มากที่สุด<br/>(5)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">มาก<br/>(4)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">ปานกลาง<br/>(3)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">น้อย<br/>(2)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">น้อยที่สุด<br/>(1)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">คะแนนเฉลี่ย</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">ร้อยละ</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(l,i) in list" :key="i">
      <th style="border: 1px solid #dee2e6;text-align: center" scope="row">{{ i+1 }}.</th>
      <!-- <td style="width:150px"><img :src="l.path_image" style="width:100%"></td> -->
      <td style="border: 1px solid #dee2e6;">{{l.first_last_name}}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.all }}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.five }}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.four }}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.three}}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.two}}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.one}}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ (((l.one*1)+(l.two*2)+(l.three*3)+(l.four*4)+(l.five*5))/l.all).toFixed(2) }}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ ((((l.one*1)+(l.two*2)+(l.three*3)+(l.four*4)+(l.five*5))*100)/(l.all*5)).toFixed(2)}}</td>
    </tr>
  </tbody>
</table>
<table class="table table-borderless" id="tblData" ref="document" v-else>
  <thead>
    <tr>
  <td style="text-align:center;" colspan="10"><span style="font-weight:bold;font-size:20px">รายงานผลการประเมินความพึงพอใจการให้บริการรายหน่วยงาน</span><br/><span style="font-weight:normal;font-size:18px">ระหว่างวันที่ {{startdatename}} ถึงวันที่ {{enddatename}}</span></td>
</tr>
  </thead>
  <thead>
    <tr>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">ลำดับ</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">หน่วยงาน</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">จำนวนผู้ประเมิน</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">มากที่สุด<br/>(5)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">มาก<br/>(4)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">ปานกลาง<br/>(3)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">น้อย<br/>(2)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">น้อยที่สุด<br/>(1)</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">คะแนนเฉลี่ย</th>
      <th style="border: 1px solid #dee2e6;text-align: center;vertical-align: top;" scope="col">ร้อยละ</th>

    </tr>
  </thead>
  <tbody>
    <tr v-for="(l,i) in list" :key="i">
      <th style="border: 1px solid #dee2e6;text-align: center" scope="row">{{ i+1 }}.</th>
      <!-- <td style="width:150px"><img :src="l.path_image" style="width:100%"></td> -->
      <td style="border: 1px solid #dee2e6;">{{l.office_name}}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.all }}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.five }}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.four }}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.three}}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.two}}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ l.one}}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ (((l.one*1)+(l.two*2)+(l.three*3)+(l.four*4)+(l.five*5))/l.all).toFixed(2) }}</td>
      <td style="border: 1px solid #dee2e6;text-align: center">{{ ((((l.one*1)+(l.two*2)+(l.three*3)+(l.four*4)+(l.five*5))*100)/(l.all*5)).toFixed(2)}}</td>
    </tr>
  </tbody>
</table>
</div>
<div
      class="modal fade"
      id="DeleteEva"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบข้อมูลการประเมิน</h5>
            
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger" @click="deletedatabyid()">
              ลบข้อมูล
            </button>
            <button
            id="closeddeleteva"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script src="https://eva-transport.visdchiangrai.com/picker_date.js"></script>

<script>
import EvaluationService from '../services/EvaluationService.js'
import html2pdf from "html2pdf.js";

export default {
  name: "Nav",  
  props: {
    msg: String,
  },
  data() {
    return {
      type:'',
      statustable:false,
      typename:'',
      list:[],
      id:'',
      startdate:'',
      enddate:'',
      startdatename:'',
      enddatename:'',
      json_fields: {},
        json_data: [],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
    }
  },
  mounted() {
    this.getstatus(1)
    this.list = []
    var date = new Date()
    var y = date.getUTCFullYear() +543
    y = y-2566
    console.log(y);
    picker_date(document.getElementById("start_date"), { year_range: "-0:+"+y });
    picker_date(document.getElementById("end_date"), { year_range: "-0:+0"+y });
    this.startdate = ''
    this.enddate = ''
  },
  methods: {
    createddate(value) {   
  var streetaddress = String(value);
    var d = streetaddress.substring(0, streetaddress.indexOf("/"));
    var m = streetaddress.substring(5, streetaddress.indexOf("/"));
    m = m.substring(1, 3);
    var y = streetaddress.substring(5);
    var full = m + "/" + d + y;
    // console.log(full);
    var date = new Date(full);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()-543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  year + "-" + month + "-" + day;
  },
    search(){
      if(document.getElementById("start_date").value == '' ||document.getElementById("end_date").value ==''){
alert('กรุณาเลือกวันที่ให้ถูกต้อง')
      }else{
      this.startdate = this.createddate(document.getElementById("start_date").value);
      this.enddate = this.createddate(document.getElementById("end_date").value);
      this.getdata()
      this.startdatename = this.getdateth(this.startdate)
      this.enddatename = this.getdateth(this.enddate)
      }
    },
    getdateth(date){
      var dstart  = date.split('-')
      const dateend = new Date(dstart[0], dstart[1]-1, dstart[2])
      const result = dateend.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
})
return result
    },
    exportToPDF() {
      this.statuspdf = false;
      html2pdf(this.$refs.document, {
        // [top, left, bottom, right]
        margin: [1, 1, 0, 1],
        filename: 'รายงานผลการประเมินความพึงพอใจการให้บริการรายบุคคล'+this.typename + ".pdf",
        jsPDF: { unit: "cm", format: "a4", orientation: "p" },
      });
      setTimeout(function () {
        location.reload();
      }, 1500);
      window.scrollTo(0, 0);
    },
    exportTableToExcel(tableID, filename = ''){
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    if (this.type == 1) {
      filename = 'รายงานผลการประเมินความพึงพอใจการให้บริการรายบุคคล'
    }else{
      filename = 'รายงานผลการประเมินความพึงพอใจการให้บริการรายหน่วยงาน'
    }
    filename = 
    // Specify file name
    filename = filename?filename+'.xls':'excel_data.xls';
    
    // Create download link element
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
    
        // Setting the file name
        downloadLink.download = filename;
        
        //triggering the function
        downloadLink.click();
    }
},
    deletedataEva(id){
this.id = id
    },
    deletedatabyid(){
      EvaluationService.deleteeva(this.id).then(()=>{
  document.getElementById("closeddeleteva").click();
            this.getstatus(this.type);
})
    },
getstatus(type){
  this.type = type
  if (this.type == 1) {
      this.statustable = true
      this.typename = 'บุคคล'
    }else{
      this.statustable = false
      this.typename = 'หน่วยงาน'
    }
    this.getdata()
},
getdata(){
  console.log(this.type);
if (this.type == 1) {
  EvaluationService.getevainvreport(this.startdate,this.enddate).then((res=>{
    console.log(res.data);
    this.list = res.data
    
  }))
    
}else{
  EvaluationService.getevaoffreport(this.startdate,this.enddate).then((res)=>{
    this.list = res.data
    console.log(res.data);
  })
}
}
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04AA6D;
  color: white;
}
.card {
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
}
body{
background-color: gray;
}
</style>
